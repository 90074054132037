!function($) {
  "use strict";

  $(document).ready(function() {  
    // check for existence to prevent errors in apps when jquery has been overridden
    if ($.isFunction($.prototype.fileIconize)) {
      $("#main_content").fileIconize();
    }
  });
}(window.jQuery);
