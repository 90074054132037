!function($) {
  $(document).ready(function() {
    // add a nice tool tip to glossary links
    $('a.glossary').attr('title', 'Click for definition');

    // event handler to load glossary and open dialog when you click on a glossary link
    $("body").on("click", "a.glossary", function(){
      // callback used after loading content
      function openDialog(){
        var pageFragment = $(this);
        var header = pageFragment.find("h2");

        var dialogTitle = header.text();
        header.remove();

        var content = pageFragment.html();

        window.bootbox.dialog(content, [], {
          header: dialogTitle,
          animate: false,
          backdrop: true,
          keyboard: true
        });
      }

      var url = $(this).attr("href");

      // Switch the URL from "glossary.html#B100" to "glossary.html #B100" for
      // compatibility with jQuery's Ajax .load() page fragment feature.
      url = url.replace(/#/, " #");

      // add glossary content into div, then use the callback to open it
      $("<div>").load(url, openDialog);

      return false; // don't follow the hyperlink, if there is one
    });
  });
}(window.jQuery);
