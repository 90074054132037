!function($) {
  "use strict";

  $("html").on("click", "a", function() {
    var link = this;
    var leavingSite = true;

    // Ignore javascript and mailto links.
    var exemptProtocols = ["mailto:", "javascript:"];
    if($.inArray(link.protocol, exemptProtocols) != -1) {
      leavingSite = false;
    } else if(!link.hostname) {
      leavingSite = false
    } else if(link.hostname) {
      // Check the simplest, quickest case of the links hostname being the
      // same as the current page's hostname.
      if(link.hostname == window.location.hostname) {
        leavingSite = false;
      } else {
        // Determine the base hostname of the link (eg, just "energy.gov" for
        // "www.afdc.energy.gov").
        var hostnameParts = link.hostname.split(".");
        var baseHostname = hostnameParts[hostnameParts.length - 2] + "." + hostnameParts[hostnameParts.length - 1]
        baseHostname = baseHostname.toLowerCase();

        // See if the base hostname is in a whitelist of "internal" sites or if it's a .gov.
        var internalSites = ["save-energy-now.org", "youtube.com", "cleancities.org", "litmos.com", "cleancitiessharepoint.org"];
        if($.inArray(baseHostname, internalSites) != -1 || baseHostname.endsWith(".gov")) {
          leavingSite = false;
        }
      }
    }

    if(leavingSite) {
      // Allow the warning to be disabled if the
      // "disable-external-link-warning" data attribute element is present.
      var $link = $(link);
      if($link.data("disable-external-link-warning") == true) {
        leavingSite = false;
      }
    }

    if(leavingSite) {
      return confirm("This link will take you to an external website. We are not responsible for their content.");
    } else {
      return true;
    }
  });
}(window.jQuery);
