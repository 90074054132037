(function( $ ) {
  var fileTypes = {
    doc: "icon-eere-file-doc",
    docx: "icon-eere-file-doc",
    ppt: "icon-eere-file-ppt",
    pptx: "icon-eere-file-ppt",
    xls: "icon-eere-file-xls",
    xlsx: "icon-eere-file-xls",
    pdf: "icon-eere-file-pdf"
  };
  var altText = {
    doc: " (Microsoft Word)",
    ppt: " (Microsoft PowerPoint)",
    xls: " (Microsoft Excel)",
    pdf: " (PDF)"
  }

  //Iterate over all links in selected element
  
  $.fn.fileIconize = function() {
    this.find("a").each(function() {
      if (this.href) {
         
        // Find the file extension in the URL. This regex ignores anything
        // after the anchor tag or query parameters (eg example.pdf#something
        // or example.pdf?something=else).
        var urlMatches = this.href.match(/.+\.([a-zA-Z0-9]+)(\s*|#.*|\?.*)$/);
        if(urlMatches && urlMatches[1]) {
          var extension = urlMatches[1].toLowerCase();
          var image = fileTypes[extension];
          
          // Append the image if there's one for this file type, and there
          // aren't any images already inside this link (we don't want to add
          // file icons to a linked image, like a feature icon in the right
          // column).
          if(image && $(this).find("img").length == 0 && !this.href.match("VICE_2")) {
            $('<i class="' + image + '"><span class="visuallyhidden">' + altText[extension] + '</span></i>').appendTo(this);
          }
        }
      }
    });
  }

})(window.jQuery)
