var EERE = EERE || {}
EERE.highcharts_theme = {
  title: {
    style: {
      color: '#000',
      fontFamily: 'Arial',
      fontSize: '1.385em',
      fontWeight: 'bold'
    }
  },
  legend: {
    itemStyle: {
      color: '#666',
      fontFamily: 'Arial'
    }
  },
  tooltip: {
    style: {
      fontFamily: 'Arial'
    }
  },
  xAxis: {
    labels: {
      style: {
        color: '#666',
        fontFamily: 'Arial',
        fontSize: '0.813em'
      }
    },
    title: {
      style: {
        color: '#000',
        fontFamily: 'Arial',
        fontWeight: 'bold',
        fontSize: '1em'
      }
    }
  },
  yAxis: {
    labels: {
      style: {
        color: '#666',
        fontFamily: 'Arial',
        fontSize: '0.813em'
      }
    },
    title: {
      style: {
        color: '#000',
        fontFamily: 'Arial',
        fontWeight: 'bold',
        fontSize: '1em'
      }
    }
  }
}

window.EERE = EERE;
