!function($) {
  "use strict";

  $(document).ready(function() {
    $("#modal_gallery_enlarge").on("load", function () {
      var $element = $(this);
      var modal = $element.data("modal");
      var index = modal.options.index;
      var $img = $(modal.img);
      var $link = $(modal.$links[index]);

      $img.prop("alt", $link.data("img-alt"));

      var description = $link.data("description")
      var $footerElement = $element.find(".modal-footer");
      if(description) {
        $footerElement.show();
        var $descriptionElement = $element.find(".modal-description");
        $descriptionElement.css("width", $img.prop("width"));
        $descriptionElement.html(description);
      } else {
        $footerElement.hide();
      }
    });
  });
}(window.jQuery);
