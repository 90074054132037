!function($) {
  $(document).ready(function() {
    $("#print a").click(function(event) {
      event.stopPropagation();
      event.preventDefault();

      var $window = $(window);
      var printStylesheets = $("link[media=print]");

      $window.scrollTop(0);
      $window.trigger("printPreviewEnable");

      printStylesheets.attr("data-original-media", "print").prop("media", "all");
      $("body").addClass("print-preview");

      $window.trigger("printPreviewEnabled");
    });

    $("#print_preview_print").click(function() {
      window.print();
      return false;
    });

    $("#print_preview_cancel").click(function(event) {
      event.stopPropagation();
      event.preventDefault();

      var $window = $(window);
      var printStylesheets = $("link[data-original-media=print]");

      $window.trigger("printPreviewDisable");

      $("body").removeClass("print-preview");
      printStylesheets.prop("media", "print");

      $window.trigger("printPreviewDisabled");
    });

  });
}(window.jQuery);
