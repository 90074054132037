!function($) {
  "use strict";

  $.fn.carouselPager = function(options) {
    return this.each(function() {
      var $carousel = $(this);
      $carousel.carousel(options);

      $carousel.on("slid", function() {
        var activeIndex = $carousel.find(".active").index();
        var $pagerLinks = $carousel.find(".carousel-pager a");
        $pagerLinks.removeClass("carousel-pager-active");
        $pagerLinks.eq(activeIndex).addClass("carousel-pager-active");
      });

      $carousel.find(".carousel-pager a").click(function(event) {
        event.preventDefault();
        event.stopPropagation();

        var index = $(this).index();
        $carousel.carousel(index);
      });
    });
  }
}(window.jQuery);
