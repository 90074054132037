!function($) {
  "use strict";

  $(document).ready(function() {
    $("body").on("show", ".accordion-body", function() {
      var accordionGroup = $(this).parent();
      var accordionHeading = accordionGroup.find(".accordion-heading");

      accordionHeading.removeClass("collapsed").addClass("expanded");
    });

    $("body").on("hide", ".accordion-body", function() {
      var accordionGroup = $(this).parent();
      var accordionHeading = accordionGroup.find(".accordion-heading");

      accordionHeading.removeClass("expanded").addClass("collapsed");
    });
  });
}(window.jQuery);
