import Rails from '@rails/ujs'
import $ from 'jquery'
import '@/template/jquery_global';
import 'bootstrap-sass';
import '@/template/eere/highcharts-theme';
import '@/template/eere/related_accordion';
import '@/template/eere/leaving_site';
import '@/template/eere/jquery.file_icons';
import '@/template/eere/file_icons';
import 'jquery-hoverintent/jquery.hoverIntent';
import 'bootbox/bootbox';
import '@/template/init_image_gallery';
import 'blueimp-bootstrap-image-gallery/js/bootstrap-image-gallery';
import '@/template/mega_menus';
import '@/template/print_preview';
import '@/template/glossary';
import '@/template/modal_gallary_enlarge';
import '@/template/carousel_pager';
import '@/template/accordion_headers';

Rails.start();

window.jQuery = window.$ = $;
