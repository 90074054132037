!function($) {
  "use strict";

  $(document).ready(function() {
    $("#primary_nav ul > li").hoverIntent({
      timeout: 250,
      over: function() {
        $(this).addClass("mega-menu-show");
      },
      out: function() {
        $(this).removeClass("mega-menu-show");
      }
    });

    $("#nav").hoverIntent({
      timeout: 250,
      over: function() {
        $(this).addClass("mega-menu-expanded");
      },
      out: function() {
        $(this).removeClass("mega-menu-expanded");
      }
    });

  });
}(window.jQuery);
